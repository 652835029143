import React from "react"

import { withAccountReset } from "./withAccountReset"
import { Container } from "../../Styled/Container"
import { AccountResetForm } from "./Form/AccountResetForm"

export const AccountReset = withAccountReset(() => (
  <Container>
    <AccountResetForm />
  </Container>
))
